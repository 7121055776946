import Hari from "../images/Hariprasad.jpg";
import Aman from "../images/Aman.jpg";
import Manohar from "../images/Manohar.jpg";
import Naseer from "../images/Naseer.jpg";
import Preeti from "../images/Preeti.jpg";
import Ravi from "../images/Ravi.jpg";
import Reena from "../images/Reena.jpg";
import Sanjana from "../images/Sanjana.jpg";
import SMahajan from "../images/SMahajan_informal.JPG";
import def from "../images/def.png";

const Sdata = [
    {
        imgsrc: Hari,
        title: "Hari Prasad T P N",
        designation: "President",
        dept: "Department of Life Sciences,",
        address: "Jnana Bharathi Campus, Bengaluru University, Bengaluru - 56",
        details: "Currently working as an Assistant Professor in the Department of Life Science, at Bangalore University. He did his MSc and PhD in Applied Genetics from Bangalore University. Research areas are In-vitro Fertilization, Biology and Genetics of Mosquitoes, Insecticide resistance, Molecular biology and Basic bioinformatics, Entomology and Biodiversity. Has published more than 20 research articles in reputed national and international journals and has 4 book chapters."
    },
    {
        imgsrc: Sanjana,
        title: "Sanjana S",
        designation: "Vice President",
        dept: "PhD scholar,",
        address: "IISER, Pune",
        details:"Is a PhD student in Prof Richa Rikhy's Lab (Morphogenesis and Development Lab), Biology Department, IISER Pune. Currently working on understanding epithelial cellular remodelling in early embryogenesis of Drosophila.She completed her Masters in Biological sciences from Bangalore University. Interested in science research, education and communication especially in fields of developmental biology, molecular biology, genetics and regenerative biology.Additionally, she is also a Bharatnatyam dancer currently learning Kathak."
    },
    {
        imgsrc: Manohar,
        title: "Manohar G M",
        designation: "Secretary",
        dept: "Lecturer, KRCRC, Telagarahalli, Anekal TQ",
        address: "RBPT Certified Trainer,IISER, Pune",
        details:"Has been in the field of Teaching and Lecturing for the past 20 years for students of all age groups including school students to postgraduates. He is trained in Research-based pedagogy from IISER, Pune and conducts research-based bioinformatics programs for Life Science college students. Apart from mentoring school students of halliLabs Science Learning Center and mentoring in Chemistry camps of the Royal Society of Chemistry, he was also a Science communicator for Research Matters- an online science media house from Gubbi Labs. He is an avid reader interested in trekking, astronomy, bird watching and sports."
    },
    {
        imgsrc: def,
        title: "Shankrappa K P",
        designation: "Treasurer",
        dept: "Lecturer",
        address: "KRCRC, Telagarahalli, Anekal TQ",
        details:"Presently working As the Treasurer at halliLabs and as Physics Lecturer at KRCR PU college, Anekal. An ardent nature enthusiast, science communicator and is involved in a number of nature and conservation related programs and platforms. His hobbies include nature photography and trekking and in free time enjoys reading and listening to music."
    },
    {
        imgsrc: Naseer,
        title: "Shaik Naseer Pasha",
        designation: "Scientific Adviser & Executive Member",
        dept: "PhD from NCBS,",
        address: "PDF from University of Cambridge, UK",
        details:"Experienced Research Associate in Biomedical Sciences from Cambridge University with experience working in the research industry. Skilled in the fields of Life Sciences, Genomics, Transcriptome, variant calling, variant prioritization, Molecular Modeling, and data management from plant and animal sources with a PhD in Computational Biology from the National Centre for Biological Sciences. Managed as a team leader for two of four years of experience working as a Scientist in the Biotechnology industry. Executive committee member and scientific advisor for halliLabs which is an association for implementing Research-based pedagogy in science education with school kids and college students. I volunteer my services as a mentor for mentoring college students in research-based pedagogy in bioinformatics."
    },
    {
        imgsrc: Reena,
        title: "Reena Rathod",
        designation: "Executive Member",
        dept: "PhD in neuroscience",
        address: "University of Wuerzburg, Germany",
        details:"Reena Rathod has a PhD in neuroscience from the University of Wuerzburg, Germany and did my postdoc fellowship at Instem Bangalore in collaboration with the University of Edinburgh. I have both academic as well as industrial experience in national and international settings of more than 15 years. I am currently working with Zydus Pharmaceuticals as a DGM. I am passionate about transforming the lives of children by empowering them for a bright future. I have been associated with halliLabs since 2015."
    },
    {
        imgsrc: SMahajan,
        title: "Saurabh Mahajan",
        designation: "Scientific Adviser",
        dept: " PhD from NCBS,",
        address: "",
        details:"Saurabh Mahajan is a former Assistant Professor at St. Joseph’s College and Atria University and a former visiting lecturer at the University of Pune. His research and teaching interests include Microbiology, Bioinformatics, and Computational Biology, and he has a special affinity to conduct research with undergraduate students.He has received numerous prestigious recognitions including the Dr. Shyama Prasad Mukherjee (SPM) Fellowship of the Council of Scientific and Industrial Research; CSIR-Junior Research Fellowship and NET qualification; Kishor Vaigyanik Protsahan Yojana fellowship; and a Bronze medal in the International Biology Olympiad as part of the Indian team.For his Ph.D from NCBS Saurabh studied how bacterial genomes evolve over hundreds of millions of years. Before that he has also researched how bacteria regulate their complex life and how neurons find each other while developing connections. Saurabh’s research combines diverse topics such as microbiology, genomics, evolution, bioinformatics, and computational biology. He is currently collaborating with experimental biologists to develop a novel platform for drug effect and toxicological screening."
    },
    {
        imgsrc: Preeti,
        title: "Preethi Kute",
        designation: "Executive member",
        dept: "PDF from NCBS,",
        address: "",
        details:"Preeti kute has a PhD from inStem situated in NCBS campus. She is now working as a postdoc in  University of Bergen, Norway. In her spare time she likes to hike and explore the mountains,  swimming and bouldering. She regularly like to read and listen to podcasts."
    },
    {
        imgsrc: Ravi,
        title: "Ravi Kumar Boyapati",
        designation: "Executive member",
        dept: "Research Scholar from NCBS",
        address: "",
        details:"I work at the Archives at NCBS, Bangalore as a Facility Coordinator for the Archives, before joining the Archives, I worked in a Bee Lab headed by Dr.Axel Brockmann at NCBS, as a Project Assistant. I am also a hobby photographer, and trek enthusiast, playing badminton, running, and cycling keep me going."
    },
    {
        imgsrc: Aman,
        title: "Aman Aggarwal",
        designation: "Scientific Advisers",
        dept: "PhD form NCBS",
        address: "",
        details:""
    },
    {
        imgsrc: def,
        title: "Meenakshi",
        designation: "Scientific Adviser",
        dept: "PhD from NCBS, Joint PDF,",
        address: "NCBS - University of Nairobi, Kenya"
    },
    {
        imgsrc: def,
        title: "Srikanth Lingappa",
        designation: "Scientific Adviser",
        dept: "PhD scholar from University of Bristol, UK",
        address: "",
        details:"Srikanth Lingappa graduated from Government Science College, Bangalore University in 2019 with a master’s in biotechnology. Upon graduation, Srikanth joined the National Centre for Biological Sciences (NCBS-TIFR) in Bangalore, India as a research intern, where he studied host-pathogen interactions.In 2021, Srikanth was awarded the UKRI’s BBSRC South West Biosciences PhD Fellowship and is currently pursuing his PhD at the University of Bristol, UK. Srikanth's research interests lie in computational structural biology, with a particular focus on protein engineering and design. He utilizes advanced AI computational methods to explore protein sequence-structure functions and design novel proteins with potential applications in therapeutics."
    },
    {
        imgsrc: def,
        title: "Thrinath Mullupadi",
        designation: "Online Co-Ordinator",
        dept: " PhD from NIMHANS",
        address: "",
        details:"Thrinath is a passionate researcher working on the psychoneuroimmunology of psychiatric illnesses like schizophrenia with 14 years of broad research experience and expertise in molecular biology and clinical research. He obtained his PhD from the Department of Human Genetics at NIMHANS."
    }
]
export default Sdata;