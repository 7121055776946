import React from "react";
import {
  Image,
} from "react-bootstrap";
import images from "../Pages/img";
import 'bootstrap/dist/css/bootstrap.min.css';
const Head = (props) => {
  return (
    <>
      <div className="container-fluid" style={{ padding:'6px', backgroundColor: '#003a48', color:'aliceblue'}}>
        <div class="row">
          <div class="col-sm-1 text-center my-2" >
            <Image
              src={images.logo}
              width={130}
              height={130}
              alt="halliLabsLogo"
            />
          </div>
          <div class="col-sm-7 my-4 ">
            {/* <h1 className="text-center font-weigh-dark" style={{color: '#3498db'}}>halliLabs</h1> */}
            <h1 className="text-center font-weigh-dark" style={{fontFamily:'sans-serif', color: '#FFA500'}}>halliLabs</h1>
            <p className="text-center">(Association to Implement Research based Pedagogy in Science Education)</p>
          </div>
          <div class="col-sm-3">
          <div class="row">
          <div class="col-sm-11 text-center my-4">
            <p>
              They alone live who live for others, <br />the rest are more dead than alive
              <br />
              <b> - Swami Vivekananda</b>
            </p>
          </div>
          <div class="col-sm-1 text-center my-2">
          <Image
              src={images.Vivekananda}
              rounded
              width={130}
              height={130}
            />
          </div>
          </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Head;