import React from "react";
import Card from "./Card";
import Sdata from "./Sdata";
const People = () => {
  return (
    <>
      <div className="my-5">
        <h1 className="text-center"><u>Executive Committee Members</u></h1>
      </div>
      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row gy-4">
              {
                Sdata.map((val, ind) => {
                  return <Card
                    key={ind}
                    imgsrc={val.imgsrc}
                    title={val.title}
                    dept={val.dept}
                    designation={val.designation}
                    address={val.address}
                    data={val.details}
                  />
                })
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default People;