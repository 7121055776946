import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Head from "./Components/Header/Header";
import Home from "./Components/Pages/Home"
import People from "./Components/Pages/People";
import Footer from "./Components/Footer/Footer";
import { Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";

const App = () => {
  return (
    <>
      <Head/>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/People" element={<People />} />
      </Routes>
      <Footer />

    </>
  );
};

export default App;
