import image1 from "../images/SliderImages/img1.jpg";
import image2 from "../images/SliderImages/img2.jpg";
import image3 from "../images/SliderImages/img3.jpg";
import image4 from "../images/SliderImages/img4.jpg";
import image5 from "../images/SliderImages/img5.jpg";
import image6 from "../images/SliderImages/img6.jpg";
import image7 from "../images/SliderImages/img7.jpg";
import image8 from "../images/SliderImages/img8.jpg";
import image9 from "../images/SliderImages/img9.jpg";
import image10 from "../images/SliderImages/img10.jpg";
import image11 from "../images/SliderImages/img11.jpg";
import image12 from "../images/SliderImages/img12.jpg";
import image13 from "../images/SliderImages/img13.jpg";
import image14 from "../images/SliderImages/img14.jpg";
import image15 from "../images/SliderImages/img15.jpg";
import image16 from "../images/SliderImages/img16.jpg";
import image17 from "../images/SliderImages/img17.jpg";
import image18 from "../images/SliderImages/img18.jpg";
import image19 from "../images/SliderImages/img19.jpg";
import image20 from "../images/SliderImages/img20.jpg";
import image21 from "../images/SliderImages/img21.jpg";
import image22 from "../images/SliderImages/img22.jpg";
import image23 from "../images/SliderImages/img23.jpg";
import image24 from "../images/SliderImages/img24.jpg";
import image25 from "../images/SliderImages/img25.jpg";
import image26 from "../images/SliderImages/img26.jpg";
import image27 from "../images/SliderImages/img27.jpg";
import image28 from "../images/SliderImages/img28.jpg";

export const SliderData = [
  {
    image: image1,
  },
  {
    image: image2,
  },
  {
    image: image3
  },
  {
    image: image4
  },
  {
    image: image5
  },
  {
    image: image6
  },
  {
    image: image7
  },
  {
    image: image8
  },
  {
    image: image9
  },
  {
    image: image10
  },
  {
    image: image11
  },
  {
    image: image12
  },
  {
    image: image13
  },
  {
    image: image14
  },
  {
    image: image15
  },
  {
    image: image16
  },
  {
    image: image17
  },
  {
    image: image18
  },
  {
    image: image19
  },
  {
    image: image20
  },
  {
    image: image21
  },
  {
    image: image22
  },
  {
    image: image23
  },
  {
    image: image24
  },
  {
    image: image25
  },
  {
    image: image26
  },
  {
    image: image27
  },
  {
    image: image28
  },
]
export default SliderData;