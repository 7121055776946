import React from "react";
import "./Navbar.css"
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      {/* <div className="container-fluid" style={{ padding: '1px', backgroundColor: '#22783A', color: 'aliceblue' }}> */}
      <div className="container-fluid" style={{ padding: '1px', color:'#FFFFF0'}}>
        <div className="row">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <NavLink activeClassName="menu_active" exact className="nav-link active" aria-current="page" to="/">Home</NavLink>
                    <NavLink activeClassName="menu_active" className="nav-link" to="/People">People</NavLink>
                    <NavLink activeClassName="menu_active" className="nav-link" to="/">Activities</NavLink>
                    <NavLink activeClassName="menu_active" className="nav-link" to="/">halliLabs SLC</NavLink>
                    <NavLink activeClassName="menu_active" className="nav-link" to="/">RBPTs</NavLink>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Navbar;
