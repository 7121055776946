import {
  Row,
  Col,
} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from "react";
import ImageSlider from "../Carousel/Slider";
import { SliderData } from '../Carousel/SliderData';
import '../Carousel/Slider.css'

const Home = () => {
  const [isShowMore, setIsShowMore] = useState(false);
  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      <div className='mx-4 py-2'>
        <Row>
          <Col sm={6}>
            <h2>What do we do ?</h2>
            <p>
              halliLabs works in the field of science education to implement research-based pedagogy.
              It provides a platform for informal collaboration between students, teachers, and researchers.
              The main objectives are to inculcate scientific thinking among students of all age groups.
              This is achieved through implementing Research Based Pedagogical Tools ( RBPTs) which are designed to aid deeper
              content knowledge of concepts in the curriculum as well as an introduction to the process of research.
              <br /><br />
              Through RBPTs, we believe that an opportunity can be provided to enhance the critical, logical and analytical thinking skills of the students,
              which we feel are the main lacunae in present-day science education.
              This process includes training in cognitive skills like hypothesis formulation, experimental design,
              data generation/ collection and interpretation, technical skills ( required to conduct research),
              and personal skills like collaborative learning, presentation, reporting and science writing skills.
            </p>
            {isShowMore && (
              <p>
                A significant amount of importance is given to student autonomy, right from the choice of the question to research design or the parameters to be chosen, etc.
                Mentoring usually is done in collaborative groups consisting of students, a teacher, and a researcher.
                <br /><br />
                Though authentic research may happen, halliLabs and RBPTs primary goal is teaching scientific skills and a deeper conceptual understanding of the domain knowledge.
              </p>
            )}
            <button onClick={toggleReadMoreLess}>
              {isShowMore ? "Read Less" : "Read More"}
            </button>
          </Col>
          <Col sm={6}>
            <ImageSlider slides={SliderData} />
          </Col>
        </Row>
      </div>
    </>
  )
}
export default Home;